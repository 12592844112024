@import url("https://fonts.googleapis.com/css?family=Nunito:300,400,600,700, 900&display=swap");

/* ================================================
common
================================================ */
* {
  margin: 0;
  box-sizing: border-box;
}

.banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}


.consent-banner-style {
  position: fixed;
  font-size: 14px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10vh;
  background-color: #333;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.consent-banner-style button {
  display: block;
  margin: 10px;
}

html,
body {
  width: 100%;
  font-size: 16px;
  font-family: "Noto Serif", serif;
  z-index: 10;
  margin: 0;
  box-sizing: border-box;
  color: #333;
  scroll-behavior: smooth;
}

section {
  display: block;
  margin: 0 auto;
  height: auto;
  padding: 0;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #333;
  display: inline-block;
  font-weight: 900;
  transition: all 0.3s;
}

p {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

dl,
dt,
dd {
  margin: 0;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

address {
  font-style: normal;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.pc-only {
  display: block;
}

.sp-only {
  display: none;
}

.note {
  font-size: 14px;
  margin-top: 1em;
  padding-left: 1em;
  text-indent: -1em;
}

/* button */
.conversion-button {
  display: block;
}

.conversion-button a {
  border-radius: 8px;
  background: rgb(222, 16, 0);
  background: linear-gradient(90deg, rgba(222, 16, 0, 1) 0%, rgba(255, 122, 0, 1) 100%);
  color: #fff;
  border-bottom: 5px solid #AB0C00;
  position: relative;
}

.conversion-button a::before {
  position: absolute;
  content: "";
  background: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.conversion-button a::after {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  border-top: solid 2px #CA1E11;
  border-right: solid 2px #CA1E11;
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transition: 0.3s;
  top: 50%;
  right: 32px;
}

.text-button a {
  text-decoration: underline;
}

@media (hover: hover) {
  .conversion-button a:hover {
    margin-top: 5px;
    border-bottom: 1px solid #AB0C00;
  }

  .text-button a:hover {
    text-decoration: none;
  }
}

@media (max-width: 932px) {

  html,
  body {
    font-size: 14px;
  }

  p {
    font-size: 14px;
  }

  .flex {
    flex-direction: column;
  }

  .pc-only {
    display: none;
  }

  .sp-only {
    display: block;
  }

  .note {
    font-size: 12px;
  }
}

/* ================================================
header
================================================ */
header {
  background: #fff;
  display: flex;
  padding: 18px 10%;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 999;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.35);
}

.header-logo {
  width: 176px;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 30px;
}

.header-language a {
  font-size: 18px;
}

.header-button a {
  font-size: 16px;
  padding: 14px 64px 14px 34px;
  text-align: center;
}

@media (max-width: 932px) {
  header {
    padding: 8px 4%;
  }

  .header-logo {
    width: 20%;
    min-width: 120px;
  }

  .header-right {
    gap: 16px;
  }

  .header-button a {
    padding: 10px 24px 10px 8px;
    font-size: 13px;
  }

  .conversion-button a::before {
    right: 8px;
    width: 12px;
    height: 12px;
  }

  .conversion-button a::after {
    right: 9px;
  }
}

/* ================================================
footer
================================================ */
footer {
  text-align: center;
}

.footer-box {
  display: flex;
  padding: 70px 10%;
  justify-content: center;
  gap: 60px;
  align-items: center;
}

.footer-box__logo {
  width: 300px;
}

.footer-box__adress {
  text-align: left;
}

.footer-box__adress p {
  font-size: 20px;
  font-weight: 900;
  margin-bottom: 1em;
}

.footer-box__adress dl {
  display: flex;
  justify-content: flex-start;
}

.footer-box__adress dt {
  padding-right: 1em;
}

.footer-copy {
  padding: 10px 10%;
  background: #151515;
  color: #fff;
}

@media (max-width: 932px) {
  .footer-box {
    flex-direction: column;
    gap: 20px;
    padding: 70px 4%;
  }
}

/* ================================================
main
================================================ */
.main {
  background: url(../public/images/back_top.jpg) no-repeat 0% 50% / cover;
  padding: 120px 10% 10%;
  position: relative;
  display: flex;
  align-items: center;
}

.main-img {
  position: absolute;
  width: 52%;
  left: 45%;
  top: 4%;
  max-width: 780px;
}

.main-text {
  color: #fff;
  width: 42%;
}

.main-text__lead {
  font-size: 34px;
  color: #FFC700;
  font-weight: 900;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

.main-text__lead span {
  font-size: 200%;
}

.main-text__lead::after,
h2::after {
  position: absolute;
  content: "";
  background: rgb(255, 199, 0);
  background: linear-gradient(90deg, rgba(255, 199, 0, 1) 0%, rgba(255, 209, 48, 1) 49%, rgba(255, 232, 149, 1) 70%, rgba(168, 131, 0, 1) 100%);
  width: 100%;
  height: 4px;
  bottom: 0;
  left: 0;
  display: inline-block;
}

main h1 {
  font-size: 70px;
}

h1 span {
  font-size: 60%;
}

.main-text__conversion {
  margin-top: 8%;
  display: inline-block;
}

.main-conversion__lead {
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 5%;
}

.main-conversion__button a {
  font-size: 24px;
  padding: 4% 16%;
  width: 100%;
  text-align: center;
}

@media (max-width: 932px) {
  .main {
    display: block;
    padding: 80px 4% 40px;
    position: static;
  }

  .main-text {
    width: 100%;
    text-align: center;
  }

  .main-text__lead {
    font-size: 26px;
  }

  .main h1 {
    font-size: 50px;
  }

  .main-conversion__lead {
    font-size: 20px;
  }

  .main-conversion__button a {
    font-size: 20px;
  }

  .main-img {
    position: static;
    width: 80%;
    margin: 30px auto 0;
  }
}

/* ================================================
contents common
================================================ */
.contents {
  padding: 180px 10%;
}

.contents h2 {
  font-size: 38px;
  text-align: center;
  position: relative;
  padding-bottom: 16px;
  margin-bottom: 60px;
}

.contents h2::after {
  max-width: 320px;
  left: 50%;
  transform: translateX(-50%);
}

.back-black h2 {
  color: #fff;
}

.conversion {
  text-align: center;
  margin-top: 20%;
}

.conversion p {
  font-size: 30px;
  padding-bottom: 6%;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 900;
}

.conversion p::before,
.conversion p::after {
  content: "";
  width: 80px;
  height: 5px;
  border-top: 4px solid #fff;
  top: -15px;
}

.conversion p::before {
  margin-right: 0.5em;
  transform: rotate(70deg);
}

.conversion p::after {
  margin-left: 0.5em;
  transform: rotate(-70deg);
}

.contents .conversion-button {
  max-width: 720px;
  margin: 0 auto;
}

.contents .conversion-button a {
  width: 100%;
  padding: 4% 16%;
  font-size: 32px;
}

.back-black {
  background: #151515;
  color: #fff;
}

.back-yellow {
  background: url(../public/images/back_yellow.jpg) repeat-y center / cover;
}

.back-blackFoto {
  background: url(../public/images/back_contact.jpg) repeat-y center / cover;
  color: #fff;
}

@media (max-width: 932px) {
  .contents {
    padding: 60px 4%;
  }

  .contents h2 {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .contents h2::after {
    width: 40%;
  }

  .conversion p {
    font-size: 20px;
  }

  .conversion p::before,
  .conversion p::after {
    width: 60px;
  }

  .contents .conversion-button a {
    font-size: 22px;
  }
}

/* ================================================
introduction
================================================ */
.introduction {
  position: relative;
  z-index: -1;
}

.introduction::before,
.introduction::after {
  position: absolute;
  content: "";
  width: 34%;
  height: 720px;
  bottom: -110px;
  z-index: -1;
}

.introduction::before {
  background: url(../public/images/lead_tonkothu.png) no-repeat center bottom / 100%;
  left: 2%;
}

.introduction::after {
  background: url(../public/images/lead_miso.png) no-repeat center bottom / 100%;
  right: 2%;
}

.introduction-text {
  text-align: center;
  color: #fff;
}

.introduction-text p {
  line-height: 3;
}

@media (max-width: 932px) {
  .introduction {
    padding-bottom: 90%;
    overflow: hidden;
  }

  .introduction-text p {
    text-align: left;
    line-height: normal;
  }

  .introduction::before,
  .introduction::after {
    width: 76%;
    top: auto;
    bottom: 30px;
  }

  .introduction::before {
    left: -28%;
    background-position: center bottom;
  }

  .introduction::after {
    right: -28%;
    background-position: center bottom;
  }
}

/* ================================================
feature
================================================ */
.feature-box {
  gap: 20px;
}

.feature-item__text h3 {
  margin: 1em auto;
  text-align: center;
  font-size: 22px;
}

.feature-item__text .text-button {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

@media (max-width: 932px) {
  .feature-box {
    gap: 60px;
  }

  .feature-item__text h3 {
    font-size: 20px;
    margin: .5em auto;
  }

  .feature-item__text .text-button a {
    font-size: 16px;
  }
}

/* ================================================
taste
================================================ */
.taste {
  background: url(../public/images/back_black.jpg) repeat-y top / 100%;
  color: #fff;
}

.taste h2 {
  margin-bottom: 120px;
}

.taste-box__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
}

.taste-box__item:last-child {
  margin-bottom: 0;
}

.taste-item__text {
  width: 30%;
}

.taste-item__img {
  width: 68%;
}

.taste-item__text h3 {
  font-size: 32px;
  margin-bottom: 1em;
}

.taste-item__text p {
  font-size: 18px;
}

@media (max-width: 932px) {
  .taste h2 {
    margin-bottom: 40px;
  }

  .taste-box__item {
    display: block;
    text-align: center;
  }

  .taste-item__text {
    width: 100%;
    margin-bottom: 20px;
  }

  .taste-item__text h3 {
    font-size: 20px;
    margin-bottom: .5em;
  }

  .taste-item__text p {
    font-size: 14px;
  }

  .taste-item__img {
    width: 100%;
  }

  .taste-item__tonkothu {
    display: flex;
    flex-direction: column;
  }

  .taste-item__tonkothu .taste-item__img {
    order: 2;
  }

  .taste-item__tonkothu .taste-item__text {
    order: 1;
  }
}

/* ================================================
cook
================================================ */
.cook-box {
  gap: 20px;
  margin-top: 120px;
}

.cook-box__item {
  width: calc(100% / 4);
  position: relative;
  padding: 50px 20px 20px;
  border: 1px solid #AB0C00;
  display: flex;
  flex-direction: column;
}

.cook-item__step {
  position: absolute;
  background: #AB0C00;
  color: #fff;
  text-align: center;
  top: -50px;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px;
}

.cook-step__number {
  font-size: 30px;
  line-height: 1;
}

.cook-item__text {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 10px;
  align-items: stretch;
  height: 100%;
}

.cook-text__explan {
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 100px;
}

.cook-text__point {
  flex-grow: 1;
  padding-top: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.cook-text__pointTitle {
  color: #AB0C00;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-weight: 900;
}

.cook-text__pointTitle::before,
.cook-text__pointTitle::after {
  content: "";
  width: 34%;
  height: 1px;
  border-top: 2px solid #AB0C00;
}

.cook-movie {
  width: 80%;
  margin: 16% auto 0;
}

.cook-movie video {
  width: 100%;
}

@media (max-width: 932px) {
  .cook-box {
    margin-top: 100px;
    gap: 90px;
  }

  .cook-box__item {
    width: 100%;
    padding: 40px 16px 16px;
  }

  .cook-item__step {
    padding: 10px;
  }

  .cook-item__img {
    text-align: center;
    margin: 0 auto;
  }

  .cook-text__explan {
    min-height: auto;
  }

  .cook-text__pointTitle {
    font-size: 16px;
  }

  .cook-movie {
    width: 100%;
  }
}

/* ================================================
about
================================================ */
.about-item__text {
  width: 50%;
}

.about-item__img {
  width: 46%;
}

@media (max-width: 932px) {
  .about-item__text {
    width: 100%;
    margin-bottom: 2em;
  }

  .about-item__img {
    width: 100%;
  }
}

/* ================================================
production
================================================ */
.production-lead {
  margin: 9% auto 6%;
  text-align: center;
}

.production-lead p {
  background: #AB0C00;
  display: inline-block;
  padding: 16px 30px;
  color: #fff;
  font-size: 26px;
  font-weight: 900;
}

.production-box li {
  position: relative;
  margin-bottom: 40px;
}

.production-box li:last-child {
  margin-bottom: 0;
}

.production-box h3 {
  position: absolute;
  background: #AB0C00;
  color: #fff;
  font-size: 20px;
  font-weight: 900;
  width: 200px;
  padding: 20px 10px;
  text-align: center;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.production-text {
  margin: 0 auto 0 100px;
  border: 1px solid #AB0C00;
  padding: 40px 40px 40px 140px;
}

.production-text h4 {
  font-size: 20px;
  margin-bottom: 1em;
}

.production-step__title {
  display: flex;
  margin-bottom: 20px;
  gap: 10px;
}

.production-step__number {
  width: 88px;
  position: relative;
  color: #AB0C00;
  display: inline-flex;
  justify-content: center;
  font-weight: 500;
}

.production-step__number::after {
  position: absolute;
  content: "";
  background: url(../public/images/arrow.svg) no-repeat center / contain;
  width: 88px;
  height: 12px;
  bottom: 2px;
}

.production-step__title h4 {
  margin-bottom: 0;
  font-size: 20px;
}

@media (max-width: 932px) {
  .production-box li {
    margin-bottom: 70px;
  }

  .production-lead {
    margin: 10% auto 20%;
  }

  .production-lead p {
    font-size: 20px;
    width: 100%;
  }

  .production-box h3 {
    padding: 6px 26px;
    width: auto;
    top: -30px;
    transform: none;
    left: 16px;
  }

  .production-text {
    margin: 0 auto;
  }

  .production-text {
    padding: 36px 16px 16px;
  }

  .production-text h4 {
    font-size: 18px;
    margin-bottom: .5em;
  }

  .production-step__number {
    font-size: 16px;
  }

  .production-step li {
    margin-bottom: 40px;
  }

  .production-step__title h4 {
    margin-bottom: 0;
  }
}

/* ================================================
international
================================================ */
.international {
  background: #4E3D00;
  color: #fff;
}

.international-box {
  align-items: center;
}

.international-item__text {
  width: 36%;
}

.international-item__text h3 {
  font-size: 20px;
  margin-bottom: 1em;
}

.international-item__img {
  width: 60%;
}

/* Contact */
.contact-container {
  width: 100%;
  height: 100vh;
  padding: 20vh 20vw;
  margin: auto;
  background-color: #faf2e7;
  font-family: Arial, sans-serif;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.contact-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.contact-container p {
  color: #666;
  font-size: 14px;
  margin-bottom: 20px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  text-align: left;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.required {
  color: red;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  background-color: #fff;
  box-sizing: border-box;
}

textarea {
  height: 100px;
}

.submit-button {
  padding: 12px 0;
  font-size: 18px;
  color: #fff;
  background: linear-gradient(90deg, #ff5e5e, #ff7a3c);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;
}

.submit-button:hover {
  background: linear-gradient(90deg, #ff7a3c, #ff5e5e);
}


@media (max-width: 932px) {
  .international-item__text {
    width: 100%;
    margin-bottom: 2em;
  }

  .international-item__img {
    width: 100%;
  }
}

/* ================================================
contact
================================================ */
.contact-lead {
  text-align: center;
  font-size: 32px;
  font-weight: 900;
}

@media (max-width: 932px) {
  .contact-lead {
    font-size: 26px;
    text-align: left;
    line-height: 1.6;
  }
}

/* ================================================
English
================================================ */
.english-header .header-left {
  display: flex;
  gap: 20px;
  align-items: center;
}

.english-header .header-left p {
  font-size: 20px;
  font-weight: 900;
}

.english-main h1 {
  font-size: 70px;
  line-height: 1;
  margin-top: 30px;
}

.english-main .main-text__lead {
  font-size: 32px;
}

.english-main .note {
  padding-left: 3em;
  text-indent: -3em;
}

.english-main .conversion p {
  font-size: 40px;
}

.english-main .cook-text__explan {
  min-height: 80px;
}

.english-main .contact-lead {
  font-size: 40px;
}

@media (max-width: 932px) {
  .english-header .header-left {
    flex-direction: column;
    gap: 4px;
    width: 42%;
  }

  .english-header .header-logo {
    width: 100%;
  }

  .english-header .header-left p {
    font-size: 11px;
  }

  .english-header .header-right {
    width: 50%;
  }

  .english-header .header-button {
    width: 100%;
  }

  .english-header .header-button a {
    width: 100%;
  }

  .english-main .main-text__lead {
    font-size: 24px;
  }

  .english-main h1 {
    font-size: 44px;
    margin-top: 10px;
  }

  .english-main .conversion p {
    font-size: 20px;
  }

  .english-main .taste-item__text p {
    text-align: left;
  }

  .english-main .cook-text__explan {
    min-height: auto;
  }

  .english-main .contact-lead {
    font-size: 26px;
  }
}